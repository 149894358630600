.zoom-dialog {
  max-height: 90vh;
  max-width: 90vw;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  box-shadow: 2px 2px 5px var(--accent-1-shadow);
  border: 2px solid var(--accent-1-dark);
  border-radius: 20px;
  top: 50%;
  left: 10%;
  -ms-transform: translate(-5%,-50%);
  transform: translate(-5%,-50%);
  overflow-y: scroll;
  overflow-x: hidden;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  background: #fefefe;
}
.zoom-dialog::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.zoom-opaque-background {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: #eee;
  left: 0;
  top: 0;
  opacity: 0.8;
}

.zoom-header {
  min-height: 48px;
  position: relative;
  width: 100%;
  text-align: left;
  padding-top: 8px;
  border-bottom: 1px solid var(--accent-1-light);
  background-color: var(--accent-1-lighter);
}

.zoom-title {
  width: 100%;
  padding: 0 2%;
  font-weight: bold;
  font-size: 20px;
}

.zoom-close {
  position: absolute;
  top: 8px;
  right: 8px;
}

.zoom-close button {
  color: #fff;
  padding: 0;
  margin: 0;
  width: 36px;
  height: 32px;
  font-size: 16px;
}

.likes-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.like-container,
.dislike-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.like-icon,
.dislike-icon {
  color: var(--accent-1-light);
  min-width: 30px;
  text-align: center;
  font-size: 1.5em;
  position: relative;
  top: calc(50% - 10px);
  margin-right: 1.8vw;
  background: none;
  cursor: pointer;
  text-shadow: 2px 2px 5px rgba(51, 61, 71, 0.4);
}
.dislike-container {
  margin-left: 4vw;
}

@media (pointer: fine) {
  .like-icon:hover,
  .dislike-icon:hover {
    transform: scale(1.2);
  }
  .tag:hover {
    border: 1.5px solid var(--accent-1-light);
    box-shadow: 2px 2px 5px rgba(51, 61, 71, 0.4);
    transform: scale(1.1);
    transition: 0.2s ease-out;
  }
  .zoom-in-icon:hover,
  .zoom-out-icon:hover,
  .zoom-reset-icon:hover {
    transform: scale(1.2);
    text-shadow: 2px 2px 5px rgba(51, 61, 71, 0.3);
  }
}

.image-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 95vw;
  max-height: 80vh;
}

.image {
  max-height: 80vh;
  max-width: 75vw;
}

.image-wrapper {
  overflow: hidden;
}

.image-wrapper.hasZoom {
  cursor: -moz-zoom-in;
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
}

.zoom-in-btn,
.zoom-out-btn,
.zoom-reset-btn {
  outline: none;
  color: var(--accent-1-light);

  text-align: right;
  font-size: 1em;
  background: none;

  cursor: pointer;
  height: 0;
  z-index: 20;
  align-self: center;

  border: none;
  padding: 1em;

  text-align: center;
  text-decoration: none;
  display: inline-block;
}

.details-container {
  display: flex;
  text-align: left;
  flex-direction: column;
}

.zoom-container {
  display: flex;
  width: 100%;
  max-height: 80vh;
  align-items: center;
  justify-content: center;
  background-color:  #fafafa;
  border-top: 1px solid #eee;
  overflow: hidden;
}

.tag {
  color: var(--accent-1-light);
  border: 1.5px solid var(--accent-1-light);
  box-shadow: 2px 2px 5px rgba(51, 61, 71, 0.4);
  border-radius: 20px;
  padding: 2%;
  margin: 2%;
  display: inline-block;
  cursor: pointer;
}

.details-container hr {
  width: 100%;
  border: none;
  border-bottom: solid 2px var(--accent-1-light);
  margin: 0px;
}

.title {
  color: var(--accent-1-light);
  font-family: "Playfair Display", serif;
  font-size: 1.25em;
  font-weight: 500;
  margin-bottom: 0;
}

.dateContainer {
  font-style: italic;
  text-align: right;
  margin-right: 2vw;
  font-size: 65%;
}

.date {
  margin-top: -12px;
}

.dislike-icon-anim {
  animation-name: heart-broken;
  animation-duration: 0.4s;
  animation-iteration-count: 1;
  animation-timing-function: linear;
}
@keyframes heart-broken {
  0% {
    opacity: 1;
    transform: translateY(0px) scale(1) rotate(-45deg);
  }
  25% {
    opacity: 0;
    transform: translateY(10px) scale(0.9) rotate(-90deg);
  }

  75% {
    opacity: 0;
    transform: translateY(0px) rotate(90deg) scale(0.5);
  }
}

.like-icon-anim {
  animation-name: heart-beat;
  animation-duration: 0.4s;
  animation-iteration-count: 1;
  animation-timing-function: linear;
}
@keyframes heart-beat {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  20% {
    opacity: 0.5;
    transform: scale(1.5);
  }
  30% {
    opacity: 0.5;
    transform: scale(0.5);
  }
  40% {
    opacity: 0.5;
    transform: scale(1.5);
  }
  50% {
    opacity: 0.5;
    transform: scale(0.5);
  }
  60% {
    opacity: 1;
    transform: scale(1);
  }
  75% {
    opacity: 1;
    transform: scale(0.5);
  }
}

@media only screen and (min-width: 769px) {
  .zoom-container {
    flex-direction: row;
  }

  .zoom-container .progressContainer {
    margin: 0 0 0 12px;
    padding: 0;
    width: 64px;
  }
  
  .details-container {
    width: 40vw;
    max-width: 40vw;
    align-self: baseline;

  }

  .image-container {
    padding: 0;
  }
}

@media only screen and (max-width: 768px) {

  .zoom-header {
    min-height: 36px;
  }
  .zoom-close button {
    width: 22px;
    height: 22px;
    font-size: 14px;
  }
  .zoom-title {
    font-size: 14px;
  }
  .zoom-container {
    flex-direction: column;
  }
  .image-container {
    overflow: hidden;
    padding: 0;
    flex-direction: column;
    align-items: flex-end;
  }

  .zoom-container .progressContainer{
      padding: 0;
      background-color: var(--light);
      width: 100%;
      text-align: center;
  }

  .zoom-container .toolbar {
    display: inline-flex;
  }

}
@media screen and (max-width: 430px) {
  .zoom-dialog {
    width: 90%;
  }
}